import React, { useEffect } from 'react';
import './ProductProps.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import Header from '../../Header/Header';

function ProductProps({
  ProductPropertyImg,
  ProductPropTitle,
  ProductPropPrice,
  ProductPropCode,
  ProductPropYear,
  ProductPropStock,
  ProductPropsSliderImg,
  ProductPropColorImg,
  ProductPropsSliderImg2,
  ProductPropsSliderImg3,
  ProductPropsSliderImg4,
}) {
  useEffect(() => {
    const elements = document.querySelectorAll('.fade-in');
    
    if (elements.length > 0) {
      setTimeout(() => {
        elements[0].classList.add('fade-in-visible'); 
      }, 0);
    }

    if (elements.length > 1) {
      setTimeout(() => {
        elements[1].classList.add('fade-in-visible'); 
      }, 2000); 
    }

    for (let i = 2; i < elements.length; i++) {
      setTimeout(() => {
        elements[i].classList.add('fade-in-visible');
      }, (i * 2000) + 6000); 
    }
  }, []);

  return (
    <div>
      <Header />
      <div className='ProductPropsMain'>
        <div className='ProductPropImgDiv fade-in1'>
          <img src={ProductPropertyImg} alt="Product" />
        </div>
        <div className='ProductPropContentDiv fade-in'>
          <h1>{ProductPropTitle}</h1>
          <h3>{ProductPropPrice}</h3>
          <p>Ürün Kodu: {ProductPropCode}</p>
          <p>Garanti Süresi: {ProductPropYear}</p>
          <p>Stok Durumu: {ProductPropStock}</p>
          <h4>Fiyatlarımıza KDV Dahildir!</h4>
          <button>Sepete Ekle</button>
        </div>

        <div className='ProductPropsSliderDiv fade-in'>
          <Swiper
            pagination={{ clickable: true }}
            loop={true}
            slidesPerView={3}
            spaceBetween={50}
            className="mySwiper"
          >
            <SwiperSlide><img src={ProductPropsSliderImg} alt="Slide 1" /></SwiperSlide>
            <SwiperSlide><img src={ProductPropsSliderImg2} alt="Slide 2" /></SwiperSlide>
            <SwiperSlide><img src={ProductPropsSliderImg3} alt="Slide 3" /></SwiperSlide>
            <SwiperSlide><img src={ProductPropsSliderImg4} alt="Slide 4" /></SwiperSlide>
          </Swiper>
        </div>

        <div className='ProductPropsColorDiv fade-in'>
          <p>Renk Seçenekleri</p>
          <img src={ProductPropColorImg} alt="Color options" />
        </div>
      </div>
    </div>
  );
}

export default ProductProps;
