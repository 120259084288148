import React from "react";
import "./BasketProduct.css";
import deleteImg from "../../../assets/trashImg.png";
import arrowUpImg from "../../../assets/arrowUpImg.png";
import arrowDownImg from "../../../assets/arrowDownImg.png";
function BasketProduct({
  basketProductImg,
  basketProductTitle,
  basketProductCode,
  basketProductPrice,
  productNumber,


  
})



{
  return (
    <div>
        <div className="basketProductMain">
      <div className="basketProductLeftDiv">
        <div className="basketProductImgDiv">
          <img src={basketProductImg} />
        </div>

        <div className="basketProductContentDiv">
          <h1>{basketProductTitle}</h1>
          <p className="">Ürün Kodu: {basketProductCode}</p>
          <p className="basketProductPrice">{basketProductPrice}</p>
        </div>
      </div>

      <div className="basketProductRightDiv">
        <button className="basketTrashButton">
          <img src={deleteImg} />
        </button>
        <button className="arrowButton">
          <img src={arrowUpImg} />
        </button>
        <h1>{productNumber}</h1>
        <button className="arrowButton">
          <img src={arrowDownImg} />
        </button>
      </div>
     
    </div>
    <div className="basketProductLineDiv">
        
        </div>
    </div>
  );
}

export default BasketProduct;
