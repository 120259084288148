import React from 'react'
import './Payments.css'
function Payments() {
  return (
<div className='LoginMain'>
        
        <div className='LoginFormDiv'>
            <div className='PaymentsTitleDiv'>
            <h1>Ödeme</h1>
</div>            
            
            <input placeholder='Kart Sahibinin Adı' />
            <input placeholder='Kart Numarası' />

            <div className='PaymentsContextDiv'>
            <input placeholder='Son Kullanma Tarihi' />
            <input placeholder='CVV' />

                
            </div>
            
            <button>Ödeme Yap</button>

        </div>
    </div>  )
}

export default Payments