import React, { useEffect, useRef } from 'react';
import './Products.css';
import ProductsHeader from './ProductsHeader/ProductsHeader';
import ProductDetail from './ProductsDetail/ProductDetail';
import ProductDetailImg1 from '../../assets/ProductImg1.png';
import ProductDetailImg2 from '../../assets/ProductImg2.png';
import ProductDetailImg3 from '../../assets/ProductImg3.png';
import ProductDetailImg4 from '../../assets/ProductImg4.png';
import ProductDetailImg5 from '../../assets/ProductImg5.png';
import ProductDetailImg6 from '../../assets/ProductImg5.png';
import Header from '../Header/Header';

function Products() {
  const elementsRef = useRef([]); 

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('fade-in');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 } 
    );

    elementsRef.current.forEach((element) => {
      if (element) {
        observer.observe(element);
      }
    });

    return () => {
      elementsRef.current.forEach((element) => {
        if (element) {
          observer.unobserve(element);
        }
      });
    };
  }, []);

  return (
    <div>
      <Header />
      <div className='ProductsMain'>
        <div ref={(el) => (elementsRef.current[0] = el)} className='fade-in-1'>
          <ProductsHeader />
        </div>
        <div className='ProductDetailContainer'>
          <a
            href={`/ProductProperty?ProductDetailImg=${ProductDetailImg1}&ProductTitle=${"Oxus Tv Ünitesi"}&ProductPrice=${"999.99 ₺"}`}
            ref={(el) => (elementsRef.current[1] = el)}
            className='fade-in-2'
          >
            <ProductDetail
              ProductDetailImg={ProductDetailImg1}
              ProductTitle={"Oxus Tv Ünitesi"}
              ProductPrice={"999.99 ₺"}
            />
          </a>

          <div ref={(el) => (elementsRef.current[2] = el)} className='fade-in-3'>
            <ProductDetail
              ProductDetailImg={ProductDetailImg2}
              ProductTitle={"Oxus Tv Ünitesi"}
              ProductPrice={"999.99 ₺"}
            />
          </div>

          <div ref={(el) => (elementsRef.current[3] = el)} className='fade-in-4'>
            <ProductDetail
              ProductDetailImg={ProductDetailImg3}
              ProductTitle={"Oxus Tv Ünitesi"}
              ProductPrice={"999.99 ₺"}
            />
          </div>

          <div ref={(el) => (elementsRef.current[4] = el)} className='fade-in-5'>
            <ProductDetail
              ProductDetailImg={ProductDetailImg4}
              ProductTitle={"Oxus Tv Ünitesi"}
              ProductPrice={"999.99 ₺"}
            />
          </div>

          <div ref={(el) => (elementsRef.current[5] = el)} className='fade-in-6'>
            <ProductDetail
              ProductDetailImg={ProductDetailImg5}
              ProductTitle={"Oxus Tv Ünitesi"}
              ProductPrice={"999.99 ₺"}
            />
          </div>

          <div ref={(el) => (elementsRef.current[6] = el)} className='fade-in-7'>
            <ProductDetail
              ProductDetailImg={ProductDetailImg6}
              ProductTitle={"Oxus Tv Ünitesi"}
              ProductPrice={"999.99 ₺"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Products;
