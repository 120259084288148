import React from 'react'
import './Login.css'
function Login() {
  return (
    <div className='LoginMain'>
        
        <div className='LoginFormDiv'>
            <h1>Hoş Geldin !</h1>
            <p>Lüksün Sessiz Zarafetiyle Tasarlanmış Mobilya Koleksiyonlarımızı Keşfetmek için Doğru Yerdesiniz. Kalite ve Estetiği Bir Araya Getiren Benzersiz Tasarımlarımızla Yaşam Alanlarınıza Şıklık
                Katmaya Hazır Mısınız !
            </p>
            
            <input placeholder='E-Posta' />
            <input placeholder='Şifre' />

            <div className='LoginFormContextDiv'>
            <input className='onur' type='checkbox'/>

                <p> Beni Hatırla</p>
            </div>
            
            <button>Giriş Yap</button>

        </div>
    </div>
  )
}

export default Login