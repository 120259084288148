import React from 'react'
import './DiscountCode.css'
import secretLineImg from '../../../assets/SecretLineImg.png'
function DiscountCode() {
  return (
    <div className='discountCodeMainDiv'>

        <div className='discountCodeContextDiv'>
        <h1>Gizli Kodu Bul İndirimi Kazan !</h1>
    <p>Harfler ve sayılardan oluşan gizli kodu bul %50 indirimi kazan !</p>
        </div>

    <div className='discountCodeCodeDiv'>
        <img src={secretLineImg} />
        <img src={secretLineImg} />
        <img src={secretLineImg} />
        <img src={secretLineImg} />
        <img src={secretLineImg} />
        <img src={secretLineImg} />

    </div>

    <div className='discountCodeInputDiv'>
        <input type='text' placeholder='İsim' />
        <input type='text' placeholder='Soyisim' />
        <input type='text' placeholder='E-Posta' />
        <input type='text' placeholder='Telefon' />
    </div>

    <div className='discountcodeButtonDiv'>
        <button>
            GÖNDER
        </button>
    </div>
    

    </div>
  )
}

export default DiscountCode