import React from 'react'
import './ProductSame.css'
function ProductSame({ProductSameImg}) {
  return (
    <div className='ProductSameMain'>
        <div className='ProductSameImgDiv'>
            <img src={ProductSameImg} />
        </div>
    </div>
  )
}

export default ProductSame