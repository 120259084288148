import { Route, Routes, BrowserRouter} from 'react-router-dom';
import './App.css';
import SignUp from './Components/SignUp/SignUp';
import Login from './Components/Login/Login';
import HomePage from './Components/HomePage/HomePage';
import Products from './Components/Products/Products';
import ProductProperty from './Components/ProductProperty/ProductProperty';
import Payments from './Components/Payments/Payments';
import Basket from './Components/Basket/Basket';
import Profile from './Components/Profile/Profile';
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/SignUp' element={<SignUp/>}  />
        <Route path='/Login' element= {<Login/>} />
        <Route path='/HomePage' element= {<HomePage/>} />
        <Route path='/Products' element= {<Products/>} />
        <Route path='/ProductProperty' element= {<ProductProperty/>} />
        <Route path='/Payments' element= {<Payments/>} />
        <Route path='/Basket' element= {<Basket/>} />
        <Route path='/Profile' element= {<Profile/>} />





      </Routes>
    </div>
  );
}

export default App;
