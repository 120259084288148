import React from 'react';
import './ProductProperty.css';
import ProductProps from './ProductProps/ProductProps';
import ProductPropertyImg from '../../assets/ProductPropertySmallImg.png';
import ProductPropertySmallImg from '../../assets/ProductPropertyImg.png';
import colorImg from '../../assets/colorImg.png';
import ProductExplain from './ProductExplain/ProductExplain';
import ProductSame from './ProductSame/ProductSame';
import ProductSameImg from '../../assets/ProductSameImg.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import Header from '../Header/Header';
import { useLocation } from 'react-router-dom';

function ProductProperty() {

  
  return (
   <div>
    <Header />
    <div className='ProductPropertyMain '>
      <ProductProps  
        ProductPropertyImg={ProductPropertyImg} 
        ProductPropTitle={"Oxus Koltuk Takımı"} 
        ProductPropPrice={"4.890,00 ₺"} 
        ProductPropCode={"74859857984"} 
        ProductPropYear={"24 Ay"} 
        ProductPropStock={"Var"}
        ProductPropsSliderImg={ProductPropertySmallImg}  
        ProductPropsSliderImg2={ProductPropertySmallImg}  
        ProductPropsSliderImg3={ProductPropertySmallImg}  
        ProductPropsSliderImg4={ProductPropertySmallImg} 
        ProductPropColorImg={colorImg} 
      />

      <div className='ProductExplainContainers fade-in'>
        <ProductExplain 
          ProductExplainText={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."} 
        />
      </div>       

      <div className='productSameContainer fade-in'> 
        <div className='fenerbahceDiv'>
          <h1 className='fenerbahçe'>Benzer Ürünler</h1>
        </div>
        <div className='ProductSameImgDiv'>
          <Swiper
            loop={true}
            slidesPerView={5} 
            spaceBetween={20}
            className="mySwiper"
          >
            <SwiperSlide><ProductSame ProductSameImg={ProductSameImg}/></SwiperSlide>
            <SwiperSlide><ProductSame ProductSameImg={ProductSameImg}/></SwiperSlide>
            <SwiperSlide><ProductSame ProductSameImg={ProductSameImg}/></SwiperSlide>
            <SwiperSlide><ProductSame ProductSameImg={ProductSameImg}/></SwiperSlide>
            <SwiperSlide><ProductSame ProductSameImg={ProductSameImg}/></SwiperSlide>
            <SwiperSlide><ProductSame ProductSameImg={ProductSameImg}/></SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
   </div>
  );
}

export default ProductProperty;
