import React from 'react'
import './Profile.css'
import Header from '../Header/Header'
import PermIdentityIcon from '@mui/icons-material/PermIdentity';

function Profile() {
  return (
    <div className='berke'>
        <Header />
        <div className='ProfileMain'>

            <div className='ProfileLeftDiv'>
                <div className='ProfileTitleDiv'>
                    <PermIdentityIcon/>
                    <p>Merhaba Onur Çimen</p>
                </div>
                <ul>
                    <li>Üyelik Bilgilerim</li>
                    <li>Siparişlerim</li>
                    <li>Favori Ürünlerim</li>
                    <li>Yorumlarım</li>
                    <li>Geçmiş Siparişlerim</li>
                    <li>Güvenlik</li>
                    <li>Çıkış Yap</li>

                </ul>
            </div>

            <div className='ProfileRightDiv'>
                <h1>Üyelik Bilgilerim</h1>
            <div className='ProfileRightDivFlex'>
            <div>
                    <p>Ad</p>
                    <input className='input' type='text' placeholder='İsim' />
                </div>
                <div>
                    <p>Soyad</p>
                    <input className='input' type='text' placeholder='Soyad' />
                </div>
                <div>
                    <p>Doğum Tarihi</p>
                    <input className='input' type='date' placeholder='Doğum Tarihi' />
                </div>
                <div>
                    <p>Cinsiyet</p>
                    <select className='input'>
                        <option>Erkek</option>
                        <option>Kız</option>

                    </select>
                </div>

              
                <div>
                    <p>Telefon Numarası</p>
                    <input className='input' type='tel' placeholder='Telefon Numarası'  />
                </div>

                <div>
                    <button>Güncelle</button>
                </div>
            </div>
            </div>
        </div>

    </div>
    )
}

export default Profile