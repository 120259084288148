import React, { useState } from 'react'
import './ProductExplain.css'

function ProductExplain({ProductExplainText}) {

    const [activeTab, setActiveTab] = useState('description');

    const renderContent = () => {
        switch (activeTab) {
            case 'description':
                return <p>{ProductExplainText}</p>;
            case 'reviews':
                return <p>{ProductExplainText}</p>;
            case 'payment':
                return <p>{ProductExplainText}</p>;
            default:
                return null;
        }
    };
  return (
    <div className='ProductExplainMain'>
    <div className='ProductExplainButtonDiv'>
        <button
            className={activeTab === 'description' ? 'active' : ''}
            onClick={() => setActiveTab('description')}
        >
            Ürün Açıklaması
        </button>
        <button
            className={activeTab === 'reviews' ? 'active' : ''}
            onClick={() => setActiveTab('reviews')}
        >
            Yorumlar
        </button>
        <button
            className={activeTab === 'payment' ? 'active' : ''}
            onClick={() => setActiveTab('payment')}
        >
            Ödeme Seçenekleri
        </button>
    </div>
    <div className='ProductExplainTextDiv'>
        {renderContent()}
    </div>
</div>
  )
}

export default ProductExplain