import React from 'react'
import './BasketAdress.css'
function BasketAdress({BasketAdressText, BasketAdressTitle}) {
  return (
    <div className='BasketAdressMain'>
        
        <h1> {BasketAdressTitle}</h1>
        <p>{BasketAdressText}</p>

        

    </div>
  )
}

export default BasketAdress