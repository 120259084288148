import React, { useEffect, useState, useRef } from 'react';
import './HomePage.css';

import CouchType from './CouchType/CouchType';
import DiscountCode from './DiscountCode/DiscountCode';
import Header from '../Header/Header';
import homepage1 from '../../assets/homepage1.png';
import homepage2 from '../../assets/homepage2.jpg'
import homepage3 from '../../assets/homepage3.png';
import web1 from '../../assets/web1.png';
import web2 from '../../assets/web2.png';
import web3 from '../../assets/web3.png';

function HomePage() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const elementsRef = useRef([]);

  const GoProductProperty = () => {
    window.location.href = `/Products`;
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('fade-in');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    elementsRef.current.forEach((element) => {
      if (element) {
        observer.observe(element);
      }
    });

    return () => {
      elementsRef.current.forEach((element) => {
        if (element) {
          observer.unobserve(element);
        }
      });
    };
  }, []);

  return (
    <div className='homePageContainer'>
      <Header showMenuIcon={true} />
      <div className='homePageMain'>
        <button
          onClick={() => {
            GoProductProperty();
          }}
          ref={(el) => (elementsRef.current[0] = el)}
          className='homeButton fade-in-1'
        >
          <img src={isMobile ? web1 : homepage1} alt="Home Image 1" />
        </button>

        <button
          onClick={() => {
            GoProductProperty();
          }}
          ref={(el) => (elementsRef.current[1] = el)}
          className='homeButton fade-in-2'
        >
          <img src={isMobile ? web2 : homepage2} alt="Home Image 2" />
        </button>

        <button
          onClick={() => {
            GoProductProperty();
          }}
          ref={(el) => (elementsRef.current[2] = el)}
          className='homeButton fade-in-3'
        >
          <img src={isMobile ? web3 : homepage3} alt="Home Image 3" />
        </button>

        <h1 ref={(el) => (elementsRef.current[3] = el)} className='fade-in-4'>
          Lüksün Sessiz Zerafetini Keşfedin!
        </h1>

        <div ref={(el) => (elementsRef.current[4] = el)} className='fade-in-5'>
          <CouchType />
        </div>

        <div ref={(el) => (elementsRef.current[5] = el)} className='fade-in-6'>
          <DiscountCode />
        </div>
      </div>
    </div>
  );
}

export default HomePage;
