import React from 'react'

function PaymentMethod() {
  return (
    <div>
         <div className="paymentMethodContainer">
          <h1>Ödeme Yöntemi Seç</h1>
         <div className="paymentMethodButtonDiv">
         <button>
            <h1> Havale İle Ödeme</h1>
            <p>4.890,00 ₺ </p>
          </button>
          <button>
            <h1>Kart İle Ödeme</h1>
            <p>4.890,00 ₺</p>
          </button>
          <button>
            <h1>Mağazada Ödeme</h1>
            <p>4.890,00 ₺</p>
          </button>
         </div>
        </div>
    </div>
  )
}

export default PaymentMethod