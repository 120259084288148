import React, { useEffect, useRef } from "react";
import "./Basket.css";
import BasketProduct from "./BasketProduct/BasketProduct";
import basketProductImg from "../../assets/basketImg.png";
import BasketAdress from "./BasketAdress/BasketAdress";
import PaymentMethod from "./PaymentMethodSelect/PaymentMethod/PaymentMethod";
import Header from "../Header/Header";


function Basket() {
  const elementsRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("fade-in");
            observer.unobserve(entry.target); 
          }
        });
      },
      { threshold: 0.1 } 
    );

    elementsRef.current.forEach((element) => {
      if (element) {
        observer.observe(element);
      }
    });

    return () => {
      elementsRef.current.forEach((element) => {
        if (element) {
          observer.unobserve(element);
        }
      });
    };
  }, []);

  return (
    <div>
      <Header />
      <div className="BasketMain">
        <div ref={(el) => (elementsRef.current[0] = el)} className="basketTitleDiv fade-in-1">
          <h1>Sepetim</h1>
        </div>

        <div ref={(el) => (elementsRef.current[1] = el)} className="basketProductContainer fade-in-2">
          <BasketProduct
            basketProductImg={basketProductImg}
            basketProductTitle={"Oxus Koltuk Takımı"}
            basketProductCode={"367647634B7834873"}
            basketProductPrice={"4.890,00 ₺"}
            productNumber={"1"}
          />
          <BasketProduct
            basketProductImg={basketProductImg}
            basketProductTitle={"Oxus Koltuk Takımı"}
            basketProductCode={"367647634B7834873"}
            basketProductPrice={"4.890,00 ₺"}
            productNumber={"1"}
          />
        </div>

        <div ref={(el) => (elementsRef.current[2] = el)} className="BasketAdressContainer fade-in-3">
          <BasketAdress
            BasketAdressTitle={"Teslimat Adresi"}
            BasketAdressText={
              "Sularbaşı mahallesi pekmek sokak altunoğlu apartmanı 3/2 Merkez/Sivas"
            }
          />

          <PaymentMethod />

          <BasketAdress
            BasketAdressTitle={"Fatura Adresi"}
            BasketAdressText={
              "Sularbaşı mahallesi pekmek sokak altunoğlu apartmanı 3/2 Merkez/Sivas"
            }
          />
        </div>

        <div ref={(el) => (elementsRef.current[3] = el)} className="basketInputsDiv fade-in-4">
          <div className="basketInputs">
            <input type="checkbox" />
            <p>
              <span>Mesafeli Satış Sözleşmesini</span> Okudum ve Onaylıyorum
            </p>
          </div>

          <div className="basketInputs">
            <input type="checkbox" />
            <p>
              <span>KVKK Metnini</span> Okudum ve Onaylıyorum
            </p>
          </div>

          <div className="PaymentButtonDiv">
            <button className="PaymentButton">Ödeme Adımına Geç</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Basket;
