import React from 'react'
import './CouchType.css'
import couchImg from '../../../assets/couchImg.png'
function CouchType() {
  return (
    <div>
        <div className='couchDiv'>
    <div>
        <img src={couchImg} alt="Koltuk Takımı" />
        <h3>Oturma Odası</h3>
    </div>
    <div>
        <img src={couchImg} alt="Koltuk Takımı" />
        <h3>Oturma Odası</h3>
    </div>
    <div>
        <img src={couchImg} alt="Koltuk Takımı" />
        <h3>Oturma Odası</h3>
    </div>
    <div>
        <img src={couchImg} alt="Koltuk Takımı" />
        <h3>Oturma Odası</h3>
    </div>
    <div>
        <img src={couchImg} alt="Koltuk Takımı" />
        <h3>Oturma Odası</h3>
    </div>
    <div>
        <img src={couchImg} alt="Koltuk Takımı" />
        <h3>Oturma Odası</h3>
    </div>
</div>
    </div>
  )
}

export default CouchType