import React, { useEffect, useState } from "react";
import "./Header.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SearchIcon from "@mui/icons-material/Search";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import MenuIcon from "@mui/icons-material/Menu"; 
import CloseIcon from "@mui/icons-material/Close"; 
import { useLocation, useNavigate } from "react-router-dom"; 
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

function Header({ showMenuIcon }) {
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false); 
  const location = useLocation();
  const navigate = useNavigate(); 

  const handleScroll = () => {
    setScrolled(window.scrollY > 50);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const isHomePage = location.pathname === "/HomePage";

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleMenuItemClick = (path) => {
    navigate(path); 
    toggleMenu(); 
  };

  return (
    <div className={`HeaderMain ${isHomePage ? scrolled ? "scrolled" : "" : "scrolled"}`}>
      <a className="a">
        {showMenuIcon ? (
          <MenuIcon className={`icon ${isHomePage ? scrolled ? "hidden" : "" : ""}`} onClick={toggleMenu} />
        ) : (
          <ArrowBackIosIcon className={`icon ${isHomePage ? scrolled ? "hidden" : "" : ""}`} onClick={() => navigate(-1)} />
        )}
      </a>
      <div className={`headerLogDiv ${isHomePage ? scrolled ? "" : "animate" : ""}`}>
        <h1 className={`${isHomePage ? "homePageTitle" : "defaultTitle"}`}>
          OXUS
        </h1>
      </div>
      <div className={`headerButtonDiv ${isHomePage ? scrolled ? "hidden" : "" : ""}`}>
        <button className="IconButton">
          <SearchIcon />
        </button>
        <a href={`/Basket`}>
          <button className="IconButton">
            <ShoppingBagIcon />
          </button>
        </a>
        <a href={`Profile`}>
          <button className="IconButton">
            <AccountCircleIcon />
          </button>
        </a>
      </div>

      {menuOpen && (
        <div className="sidebarMenu">
          <div className="menuHeader">
            <h2>Menü</h2>
            <CloseIcon className="closeIcon" onClick={toggleMenu} /> 
          </div>
          <ul>
            <li onClick={() => handleMenuItemClick('/HomePage')}>Ana Sayfa</li>
            <li onClick={() => handleMenuItemClick('/Profile')}>Profil</li>
            <li onClick={() => handleMenuItemClick('/Basket')}>Sepetim</li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default Header;
