import React from 'react';
import './ProductsHeader.css';
import filterImg from '../../../assets/filterImg.svg';
import arrowImg from '../../../assets/arrowImg.svg';

function ProductsHeader() {
  return (
    <div className='ProductsHeaderMain'>
      <div className='ProductsHeaderTitleDiv'>
        <h1>Oturma Odası</h1>
      </div>

      <div className='ProductsRightDiv'>
        <div className='ProductsHeaderFilterDiv'>
          <img src={filterImg} alt="Filter" />
          <h5>Filtrele</h5>
        </div>

        <div className='ProductsHeaderFilterDiv'>
          <img src={arrowImg} alt="Sort" />
          <h5>Sırala</h5>
        </div>
      </div>
    </div>
  );
}

export default ProductsHeader;
