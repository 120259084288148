import React from 'react'
import './SignUp.css'
function SignUp() {
  return (
    <div className='signUpMain'>
        
        <div className='signUpFormDiv'>
            <h1>Kayıt Ol !</h1>
            <input placeholder='İsim' />
            <input placeholder='Soyisim' />
            <input placeholder='E-Posta' />
            <input placeholder='Telefon' />
            <input placeholder='Şifre' />

            <div className='signUpFormContextDiv'>
            <input className='onur' type='checkbox'/>
            <p> <span>Kullamıcı Sözleşmesini</span> okudum kabul ediyorum</p>
            </div>
            
            <div className='signUpFormContextDiv'>
            <input className='onur' type='checkbox'/>
            <p> <span>Kullamıcı Sözleşmesini</span> okudum kabul ediyorum</p>
            </div>
          
            <button className='signUpButton'>Kayıt Ol</button>

        </div>
    </div>
  )
}

export default SignUp