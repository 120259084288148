import React from 'react'
import './ProductDetail.css'

function ProductDetail({ProductDetailImg, ProductTitle, ProductPrice}) {
  return (
<a >
<div className='ProductDetailMain'>
        <img src={ProductDetailImg} />
        <h1>{ProductTitle}</h1>
        <h4>{ProductPrice}</h4>
    </div>
</a>
  )
}

export default ProductDetail